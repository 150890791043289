<template>
  <go-container root="go-investmentphone" pagename="招商" arrowBg="#93d500">
    <section :style="gSection">
      <phone-investone></phone-investone>
    </section>
    <section :style="gSection">
      <phone-investtwo></phone-investtwo>
    </section>
    <section :style="gSection">
      <phone-investthree></phone-investthree>
    </section>
    <section :style="gSection">
      <phone-investfour></phone-investfour>
    </section>
  </go-container>
</template>

<script>
export default {
  name: "e-e",
  created() {},
  methods: {},
  computed: {
    gSection() {
      return `height:${window.innerHeight}px`;
    },
  },
  created() {
    if (localStorage.getItem("load")) {
      localStorage.removeItem("load");
    } else {
      setTimeout(() => {
        localStorage.setItem("load", true);
        location.reload();
      }, 1000);
    }
  },
};
</script>

<style>
section {
  width: 100vw;
  overflow: hidden;
}
</style>
