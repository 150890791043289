import request from '../../../dynamic/request'
export default [
    {
        label: "申请人全称",
        hint: "与营业执照/身份证名称保持一致",
        error: "请填写完整的信息",
        required: true,
        showerror: false,
        name: "applicant_name",
        regular: (content) => content.trim().length
    },
    {
        label: "注册邮箱",
        hint: "请填写正确的邮箱地址",
        error: "请填写正确的邮箱地址",
        required: true,
        name: "email",
        showerror: false,
        regular: (content) => /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.]){1,2}[A-Za-z\d]{2,5}$/.test(content)
    },
    {
        label: "注册手机号",
        hint: "可通过该手机号找回密码",
        required: true,
        error: "请填写正确的手机号",
        name: "phone",
        showerror: false,
        regular: (content) => /^[1][3,4,5,7,8][0-9]{9}$/.test(content)
    },
    {
        label: "联系人姓名",
        hint: "请填写联系人姓名",
        required: true,
        error: "请填写完整的信息",
        name: "contact_name",
        showerror: false,
        regular: (content) => content.trim().length
    },
    {
        label: "联系人职务",
        hint: "请填写联系人职务",
        required: true,
        error: "请填写完整的信息",
        name: "contact_job",
        showerror: false,
        regular: (content) => content.trim().length
    }

    // ,
    // {
    //     label: "推荐人手机",
    //     hint: "请正确填写手机号",
    //     error: "请填写正确,推荐人信息不可后补或更改",
    //     errorcolor:"gray",
    //     showerror: true,
    //     required: false,
    //     name: "suggest_phone",
    //     regular: (content) => (''+content).length==0||/^[1][3,4,5,7,8][0-9]{9}$/.test(content)
    // }
]
export const url = "/api/e9b849a515a84327b424af7ccdbf2949/mobile/common/v1_0_0/coop/collect"
export const verifyRequest = async function (data) {
    return await request({
        url,
        method: "POST",
        data: {
            version: "1.0.0",
            content: {
                platform: "web",
                ...data
            },
        },
    })
}
