import { defineCustomElement } from 'vue';
import regInvest from './asset-investment/custom-element/regFunc'
import regCommon from './asset-common/regFunc'
import Container from './Container_go.vue'
import Investmentp from './investment/phone_go.vue'
import Investmentw from './investment/window_go.vue'
import InfoCollectw from './infoCollect/window_go.vue'
import InfoCollectp from './infoCollect/phone_go.vue'
export default function(){
    regCommon();
    regInvest();
    customElements.define('go-container',defineCustomElement(Container))
    customElements.define('go-investmentphone',defineCustomElement(Investmentp))
    customElements.define('go-investmentwindow',defineCustomElement(Investmentw))
    customElements.define('go-infocollectwindow',defineCustomElement(InfoCollectw))
    customElements.define('go-infocollectphone',defineCustomElement(InfoCollectp))
}
