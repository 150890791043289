<template>
  <div class="watch" :style="gStyle()">
    <div class="hour" :style="gDeg(hourDeg)"></div>
    <div class="minute" :style="gDeg(minuteDeg)"></div>
    <div class="second" :style="gDeg(secondDeg)"></div>
    <div class="circle"></div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import watch from "../../asset-investment/img/common/page3/watch.png";
export default {
  name: "real-watch",
  props: {
    scale: {
      type: Number,
    },
  },
  setup() {
    const hourDeg = ref(0);
    const minuteDeg = ref(0);
    const secondDeg = ref(0);
    return {
      watch,
      hourDeg,
      minuteDeg,
      secondDeg,
    };
  },
  created() {
    this.setDeg();
    this.timer = setInterval(() => {
      this.setDeg();
    }, 1000);
  },
  unmounted() {
    clearInterval(this.timer);
  },
  methods: {
    gStyle() {
      let scale = "";
      if (this.scale) {
        scale = `transform:scale(${this.scale})`;
      }
      return `width:100px;height:100px;background-image:url(${watch});${scale}`;
    },
    gDeg(deg) {
      return `transform:rotate(${deg}deg)`;
    },
    setDeg() {
      const { hourDeg, minuteDeg, secondDeg } = this.getDeg();
      this.hourDeg = hourDeg;
      this.minuteDeg = minuteDeg;
      this.secondDeg = secondDeg;
    },
    getDeg() {
      const time = new Date();
      const hour = time.getHours() % 12;
      const minute = time.getMinutes();
      const second = time.getSeconds();
      const hourDeg = (360 / 12) * hour;
      const minuteDeg = (360 / 60) * minute;
      const secondDeg = (360 / 60) * second;
      return {
        hourDeg,
        minuteDeg,
        secondDeg,
      };
    },
  },
};
</script>

<style>
div.watch {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  transform-origin: left top;
  height: 100px;
  width: 100px;
  --shadow: 0 0 1px 0 #ddd, 0 0 4px 0 rgba(242, 242, 242, 0.77);
}
.watch,
.second,
.hour,
.minute {
  display: inline-block;
  position: absolute;
  height: 40px;
}
.circle {
  position: absolute;
  top: 46px;
  height: 8px;
  width: 8px;
  left: 46px;
  background: #fff;
  z-index: 2;
  border-radius: 50%;
  box-shadow: var(--shadow);
}
.second,
.hour,
.minute {
  transform-origin: bottom;
  width: 4px;
  top: 10px;
  background: #fff;
  margin: 0 48px;
  border-radius: 3px;
  box-shadow: var(--shadow);
  /* transition: transform 0.5s linear; */
}
.second {
  width: 2px;
  height: 49px;
  top: 1px;
  margin: 0 49px;
  border-radius: 2px;
}
.hour {
  width: 6px;
  margin: 0 47px;
  height: 30px;
  top: 20px;
  border-radius: 5px;
}
</style>
