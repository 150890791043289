<template>
  <div class="container">
    <div class="columnBox" v-for="(title, index) in row" :key="title">
      <div class="column title">{{ title }}</div>
      <div class="column" v-for="(column, j) in datalist" :key="j">
        {{ column[index] }}
      </div>
    </div>
  </div>
  <!-- <div class="container">
    <div class="columnBox" v-for="(title, index) in row" :key="title">
      <div class="column title">{{ title }}</div>
      <div class="column" v-for="(column, j) in datalist" :key="j">
        {{ column[index] }}
      </div>
    </div>
  </div>
  <div class="container">
    <div class="columnBox" v-for="(title, index) in row" :key="title">
      <div class="column title">{{ title }}</div>
      <div class="column" v-for="(column, j) in datalist" :key="j">
        {{ column[index] }}
      </div>
    </div>
  </div>
  <div class="container">
    <div class="columnBox" v-for="(title, index) in row" :key="title">
      <div class="column title">{{ title }}</div>
      <div class="column" v-for="(column, j) in datalist" :key="j">
        {{ column[index] }}
      </div>
    </div>
  </div>
  <div class="container">
    <div class="columnBox" v-for="(title, index) in row" :key="title">
      <div class="column title">{{ title }}</div>
      <div class="column" v-for="(column, j) in datalist" :key="j">
        {{ column[index] }}
      </div>
    </div>
  </div> -->
</template>

<script>
export default {
  name: "t-tablepro",
  props: {
    row: {
      type: Array,
      required: true,
    },
    datalist: {
      type: Array,
      required: true,
    },
  },
  created() {
    // console.log(this);
  },
  methods: {},
};
</script>

<style>
/* 若需要竖直方向滚动可以设置首行
  position: sticky;
  top:0;
  设置container
  position: relative;
*/
.container {
  width: 100%;
  max-width: min-content;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #ddd;
  height: min-content;
  overflow-x: scroll;
  white-space: nowrap;
}
.container::-webkit-scrollbar {
  display: none;
}
.columnBox {
  width: min-content;
  border-left: 1px solid #ddd;
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
}
.columnBox:nth-child(1) {
  border-left: 1px solid transparent;
}
.column {
  /* width: min-content; */
  white-space: nowrap;
  flex-grow: 1;
  padding: 0 auto;
  padding: 0 1em;
  /* 宽度为每列最内容长宽度加上2em的padding */
  height: 2em;
  line-height: 2em;
  text-align: center;
  border-bottom: 1px solid #ddd;
  /* box-sizing: border-box; */
}
.title {
  color: gray;
}
.column:nth-last-child(1) {
  border-bottom: 1px solid transparent;
}
</style>
