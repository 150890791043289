<template>
  <div class="container">
    <div class="title">合作<span>流程</span></div>
    <div class="content">
      <div class="number">
        <div
          class="numberBox"
          v-for="{ index, arrow, title, steps } in content"
          :key="index"
        >
          <div class="inline" v-if="!arrow">
            <span class="colorful">0</span>
            <span class="colorful">{{ index }}</span>
            <div class="borderBox">
              <div class="numberBoxTitle">
                <span>
                  {{ title }}
                </span>
                <div class="describeBox">
                  <div class="item" v-for="(item, i) in steps" :key="i">
                    <span v-if="!item?.content">{{ item }}</span>
                    <span v-if="item?.content" class="step">{{
                      ">" + item.content
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <common-dasharrow v-if="arrow"></common-dasharrow>
        </div>
        <div class="fill"></div>
      </div>
    </div>
    <div class="box">
      <common-button93d500 setstyle="transform:scale(0.8)" @click="goto"
        >立即入驻</common-button93d500
      >
    </div>
  </div>
</template>

<script>
import { fourW } from "../../js/document";
export default {
  name: "e-e",
  setup() {
    return {
      content: fourW.data,
    };
  },
  methods: {
    goto() {
      location.href = `${location.origin}/dynamic.html/infoCollect`;
    },
  },
};
</script>

<style>
.borderBox {
  flex-grow: 1;
  height: min-content;
}
.container {
  background: #f9f9f9;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.numberBoxTitle > span {
  font-size: 1.5rem;
}
.title {
  font-size: 3.5em;
  font-weight: bold;
  /* font-family: youshe; */
  white-space: nowrap;
  padding-top: 15vh;
  margin-left: 11.5%;
  color: #4d4d4d;
}
.title span {
  color: #93d500;
}
.inline {
  white-space: nowrap;
}
.content {
  width: 85vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
}
.item {
  /* font-weight: normal !important; */
  color: #808080 !important;
  line-height: 1.8;
}
.numberBox {
  display: inline-flex;
  height: 7rem;
  flex-direction: column;
  height: min-content;
}
.number {
  width: 100%;
  height: min-content;
  display: flex;
  justify-content: space-around;
  margin-top: 2vw;
}
.numberBoxTitle {
  white-space: nowrap;
  color: #666666;
  font-weight: bold;
  width: 3.8rem;
  padding-left: 0.2em;
  overflow: visible;
  transform: translate(5%, -45%);
  margin: 0 auto;
  /* font-size: 1.2rem; */
}
.describeBox {
  width: 100%;
  overflow: visible;
  margin-top: 1em;
  height: 2em;
  font-size: 0.8rem;
  font-weight: lighter;
}
.colorful {
  background: linear-gradient(45deg, rgb(244, 246, 250), rgb(216, 224, 236));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 7em;
  font-weight: bold;
  font-style: italic;
  height: 7rem;
  line-height: 7rem;
  display: inline-block;
}
common-button93d500 {
  margin: auto;
  /* 缺少小屏方案 */
  margin-top: 6em;
}
common-dasharrow {
  /* position: absolute; */
  transform: translateY(50%);
}
.box {
  display: flex;
  width: 100%;
  padding-top: 6em;
  padding-bottom: 6em;
  height: 100vh;
  flex-shrink: 1;
}
span.step {
  font-weight: normal;
  font-size: 1.2em;
}
.fill {
  width: 40px;
  height: 100px;
}
</style>
