<template>
  <div :class="{ container: true, show: show.value }">
    <div class="success" v-if="type == 'success'">{{ content }}</div>
    <div class="error" v-if="type == 'error'">{{ content }}</div>
    <div class="plain" v-if="type == 'plain'">{{ content }}</div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      requried: true,
    },
    content: {
      type: String,
      requried: true,
    },
    show: {
      type: Object,
      requried: true,
    },
  },
  setup() {},
  data() {
    return {
      setout: "",
    };
  },
  watch: {},
};
</script>

<style>
.show.container {
  z-index: 10000;
  opacity: 1;
}
.container {
  position: absolute;
  z-index: -1;
  top: 50vh;
  left: 50%;
  width: 70vw;
  height: 50px;
  display: flex;
  transition: opacity 0.2s ease;
  transform: translate(-50%, -25px);
  justify-content: center;
  opacity: 0;
}
.success,
.error,
.plain {
  /* width: min-content; */
  height: min-content;
  min-width: 100px;
  padding: 0.5em 20px;
  text-align: center;
  white-space: pre-wrap;
  line-height: 30px;
  font-size: 1.1rem;
  border-radius: 10px;
  /* box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px; */
  background-color: white;
  border: 1px solid #aaa;
}
.error {
  color: red;
}

.plain {
  color: yellow;
}
.success {
  color: green;
}
@media only screen and (max-width: 483px) {
  .success,
  .error,
  .plain {
    width: 100%;
    white-space: nowrap;
    font-size: 1rem;
  }
}
</style>
