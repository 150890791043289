<template>
  <common-preload
    :load.prop="boxSrc.img.flat()"
    :fatherDom.prop="fatherDom"
  ></common-preload>
  <div class="container" :style="vh2px('height', 100)">
    <div class="top" :style="vh2px('padding-top', 10)">
      <div class="topBox">
        <div class="title" :style="vh2px('margin-bottom', 2)">
          我们的<span>优势</span>
        </div>
        <div class="content" :style="vh2px('margin-bottom', 5)">
          <div>垂直市场 全球视野</div>
          <div>更优质的服务 更专业的工具 更广泛的获客</div>
        </div>
        <div class="list" :style="vh2px('height', 45)">
          <common-logobox
            v-for="(v, i) in boxSrc.img"
            :key="i"
            :img.prop="v"
            :content="boxSrc.content[i]"
            :setstyle="setBoxStyle"
            >{{ boxSrc.title[i] }}</common-logobox
          >
        </div>
        <div class="buttonGroup" :style="vh2px('margin-top', 5)">
          <!-- <common-button24277 :setstyle="buttonScale + buttonMargin"
            >了解更多</common-button24277
          > -->
          <common-button93d500 :setstyle="buttonScale" @click="goto"
            >立即入驻</common-button93d500
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import off1 from "../../img/common/page2/1off.png";
import on1 from "../../img/common/page2/1on.png";
import off2 from "../../img/common/page2/2off.png";
import on2 from "../../img/common/page2/2on.png";
import off3 from "../../img/common/page2/3off.png";
import on3 from "../../img/common/page2/3on.png";
import off4 from "../../img/common/page2/4off.png";
import on4 from "../../img/common/page2/4on.png";
import { two } from "../../js/document";
export default {
  name: "e-e2",
  inject: ["vh2px"],
  setup() {
    return {
      setBoxStyle: "width:120vw",
      fatherDom: document
        .querySelector("go-investmentphone")
        .shadowRoot.querySelector("go-container phone-investtwo").shadowRoot,
      boxSrc: {
        img: [
          [off1, on1],
          [off2, on2],
          [off3, on3],
          [off4, on4],
        ],
        content: two.data.map((r) => r.detail),
        title: two.data.map((r) => r.title),
      },
      buttonMargin: ``,
      buttonScale: `width:90vw`,
    };
  },
  methods: {
    goto() {
      location.href = `${location.origin}/dynamic.html/infoCollect`;
    },
  },
};
</script>

<style>
.container {
  width: 100vw;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f9f9f9;
  --padding-left: 5vw;
}
.title {
  font-size: 2.5em;
  font-weight: bold;
  /* font-family: youshe; */
  white-space: nowrap;
  color: #4d4d4d;
  /* margin-bottom: 2vh; */
}
.content {
  /* margin-bottom: 5vh; */
}
.content {
  line-height: 1.5;
}
.title span {
  color: #93d500;
}
.buttonGroup {
  display: flex;
  flex-wrap: nowrap;
  width: 90vw;
  justify-content: center;
  /* margin-top: 5vh; */
}
.top {
  /* padding-top: 10vh; */
  display: flex;
  padding-left: var(--padding-left);
}
.topBox {
  width: min-content;
  display: flex;
  flex-direction: column;
  width: 90vw;
  box-sizing: border-box;
}
.list {
  width: 90vw;
  box-sizing: border-box;
  /* height: 45vh; */
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 5vw;
}
common-logobox {
  border-bottom: 0.5px solid #ccc;
  transform: scale(0.7);
  transform-origin: left;
}
common-logobox:nth-child(4) {
  border-bottom: none;
}
</style>
