<template>
  <div class="container" style="height: auto">
    <!--    :style="vh2px('height', 100)"-->
    <div class="title" :style="vh2px('padding-top', 10)">
      合作<span>流程</span>
    </div>
    <div class="fill1"></div>
    <div class="fill1"></div>
    <div class="fill1"></div>
    <div class="content" :style="vh2px('height', 50)">
      <div class="number" :style="setNumberStyle">
        <div
          class="numberBox"
          v-for="{ index, arrow, title, steps } in content"
          :key="index"
        >
          <div class="inline" v-if="!arrow">
            <span class="colorful">0</span>
            <span class="colorful">{{ index }}</span>
          </div>
          <div class="inline" v-if="arrow">
            <span class="colorful transparent">0</span>
            <!-- <span class="colorful">{{ index }}</span> -->
          </div>
          <div class="borderBox" v-if="!arrow">
            <div class="numberBoxTitle">
              <span>
                {{ title }}
              </span>
              <div class="describeBox">
                <div class="item" v-for="(item, i) in steps" :key="i">
                  <span v-if="!item?.content">{{ item }}</span>
                  <span
                    v-if="item?.content"
                    class="step"
                    v-html="'>' + item.content"
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <common-dasharrow v-if="arrow"></common-dasharrow>
        </div>
      </div>
    </div>
    <div class="fill1"></div>
    <div class="fill1"></div>
    <div class="fill1"></div>
    <common-button93d500 setstyle="transform:scale(0.8)" @click="goto"
      >立即入驻</common-button93d500
    >
  </div>
</template>

<script>
import { fourP } from "../../js/document";
export default {
  name: "e-ew",
  inject: ["vh2px"],
  setup() {
    return {
      setNumberStyle: `transform:scale(${
        window.innerHeight / 667 / 2
      }) translateX(-3em);height:${window.innerHeight}px`,
      content: fourP.data,
    };
  },
  methods: {
    goto() {
      location.href = `${location.origin}/dynamic.html/infoCollect`;
    },
  },
};
</script>

<style>
.borderBox {
  flex-grow: 1;
  height: min-content;
}
.container {
  background: #f9f9f9;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.numberBoxTitle > span {
  font-size: 1.4rem;
}
.title {
  font-size: 2.5em;
  font-weight: bold;
  /* font-family: youshe; */
  white-space: nowrap;
  /* padding-top: 10vh; */
  margin-left: 5vw;
  color: #4d4d4d;
}
.title span {
  color: #93d500;
}
.inline {
  white-space: nowrap;
}
.content {
  width: 90vw;
  display: flex;
  /* height: 50vh; */
  flex-direction: column;
  justify-content: center;
  margin: 2em auto;
  margin-top: 0;
}
.transparent {
  background: transparent !important;
}
.item {
  font-weight: normal !important;
  color: #808080 !important;
}
.numberBox {
  display: flex;
  height: 7rem;
  flex-wrap: nowrap;
  flex-shrink: 1;
  height: min-content;
}
.number {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.numberBoxTitle {
  white-space: nowrap;
  color: #666666;
  font-weight: bolder;
  overflow: visible;
  margin: 0 1em;
  transform: translateY(1em);
  height: 1em;
  font-size: 1.2rem;
}
.describeBox {
  width: 100%;
  overflow: visible;
  margin-top: 0.5em;
  font-size: 1rem;
  line-height: 1.7;
}
.colorful {
  background: linear-gradient(45deg, rgb(244, 246, 250), rgb(216, 224, 236));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 7em;
  font-weight: bold;
  /* font-style: italic; */
  /* 手机端 没有相关字体包会导致问题 */
  height: 7rem;
  line-height: 7rem;
  display: inline-block;
}
common-button93d500 {
  display: block;
  width: min-content;
  margin: 0 auto;
  margin-bottom: 5em;
}
common-dasharrow {
  /* position: absolute; */
  transform-origin: left center;
  transform: rotate(90deg) translate(-50%, 4px);
  /* 用0占位不算优雅 */
}
.step {
  font-weight: bold;
}
.fill1 {
  width: 1em;
  height: 1em;
}
</style>
