<template>
  <div class="container" :style="setstyle">
    <div class="textBox">
      <div class="title"><slot></slot></div>
      <div class="content">{{ content }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "common-textbox",
  setup() {},
  props: {
    content: {
      required: true,
      type: String,
    },
    setstyle: {
      type: String,
    },
  },
  methods: {},
};
</script>

<style>
.container {
  width: 40vw;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  transition: border 0.5s ease;
  border: 2px dashed transparent;
  border-radius: 10px;
}
.container:hover {
  border: 2px dashed rgb(216, 224, 236);
}
.textBox {
  padding: 0 1em;
  font-weight: lighter;
  line-height: 1.5;
}
.content {
  line-height: 1.5;
}
.title {
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 0.5em;
}
</style>
