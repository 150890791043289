<template>
  <common-preload
    :load.prop="boxSrc.img.flat()"
    :fatherDom.prop="fatherDom"
  ></common-preload>
  <div class="container">
    <div class="left">
      <div class="leftBox">
        <div class="title">我们的<span>优势</span></div>
        <div class="content">
          <div>垂直市场 全球视野</div>
          <div>更优质的服务 更专业的工具 更广泛的获客</div>
        </div>
        <div class="buttonGroup">
          <common-button93d500
            :setstyle="buttonScale + buttonMargin"
            @click="goto"
            >立即入驻</common-button93d500
          >
          <common-button24277 :setstyle="buttonScale" @click="gotoWork"
            >登录工作台</common-button24277
          >
        </div>
      </div>
    </div>
    <div class="right">
      <div class="rightBox">
        <common-logobox
          v-for="(v, i) in boxSrc.img"
          :key="i"
          :img.prop="v"
          :content="boxSrc.content[i]"
          :settitlestyle="titlestyle"
          >{{ boxSrc.title[i] }}</common-logobox
        >
      </div>
    </div>
  </div>
</template>

<script>
import off1 from "../../img/common/page2/1off.png";
import on1 from "../../img/common/page2/1on.png";
import off2 from "../../img/common/page2/2off.png";
import on2 from "../../img/common/page2/2on.png";
import off3 from "../../img/common/page2/3off.png";
import on3 from "../../img/common/page2/3on.png";
import off4 from "../../img/common/page2/4off.png";
import on4 from "../../img/common/page2/4on.png";
import { two } from "../../js/document";
export default {
  name: "e-e",
  setup() {
    return {
      titlestyle: "font-weight:bold",
      fatherDom: document
        .querySelector("go-investmentwindow")
        .shadowRoot.querySelector("go-container window-investtwo").shadowRoot,
      boxSrc: {
        img: [
          [off1, on1],
          [off2, on2],
          [off3, on3],
          [off4, on4],
        ],
        content: two.data.map((r) => r.detail),
        title: two.data.map((r) => r.title),
      },
      buttonMargin: `margin-right:1em;`,
      buttonScale: `transform-origin:left;transform:scale(0.8);`,
    };
  },
  methods: {
    goto() {
      location.href = `${location.origin}/dynamic.html/infoCollect`;
    },
    gotoWork() {
      location.href = `${location.origin}/internal.html/tools/login`;
    },
  },
};
</script>

<style>
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  min-width: 1000px;
  flex-wrap: nowrap;
  justify-content: center;
  background: #f9f9f9;
}
.title {
  font-size: 3.5em;
  font-weight: bold;
  /* font-family: youshe; */
  white-space: nowrap;
  color: #4d4d4d;
  margin-top: 15vh;
  margin-bottom: 5vh;
}
.title span {
  color: #93d500;
}
.content {
  line-height: 2;
}
.buttonGroup {
  min-width: 300px;
  white-space: nowrap;
  display: flex;
  justify-content: space-around;
  margin-top: 30vh;
}
.left {
  display: inline-flex;
  justify-content: center;
  padding-left: 5vw;
}
.leftBox {
  width: min-content;
  display: inline-flex;
  flex-direction: column;
  min-width: 350px;
  padding-right: 50px;
  box-sizing: border-box;
  margin-left: 27%;
}
.rightBox {
  width: 50vw;
  box-sizing: border-box;
  padding-top: 15vh;
  padding-bottom: 5vh;
  padding-left: 10vw;
  min-width: 500px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.left,
.right {
  flex-grow: 1;
}
.right {
  display: flex;
  justify-content: flex-end;
  padding-right: 5vw;
}
common-logobox {
  border-bottom: 0.5px solid #ccc;
}
common-logobox:nth-child(4) {
  border-bottom: none;
}
</style>
