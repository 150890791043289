<template>
  <div class="container">
    <div class="title">
      {{ title }}
    </div>
    <div class="content">
      <div class="column">
        <div class="item">
          {{ gText(posMapContent(0)) }}
        </div>
        <div class="item">
          {{ gText(posMapContent(2)) }}
        </div>
      </div>
      <div class="column">
        <div class="item">
          {{ gText(posMapContent(1)) }}
        </div>
        <div class="item">
          {{ gText(posMapContent(3)) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "g-cord",
  props: {
    content: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    posMapContent(i) {
      return this.content.filter((r) => r.hold == i)[0];
    },
    gText(item) {
      if (!item) {
        return "";
      }
      const { name, value } = item;
      return `${name}:${value}`;
    },
  },
};
</script>

<style scoped>
.container {
  width: 80%;
  height: min-content;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-weight: bold;
  overflow-x: scroll;
}
.container::-webkit-scrollbar {
  display: none;
}
.content,
.title {
  padding: 1em 5em;
  box-sizing: border-box;
  width: 100%;
}
.content {
  display: flex;
  justify-content: space-between;
}
.title {
  padding: 0.5em 5em;
  border-bottom: 1px solid #ddd;
}
.item {
  white-space: nowrap;
}
.column {
  display: inline-flex;
  width: min-content;
  min-width: 30%;
  flex-direction: column;
  align-items: stretch;
  height: 5em;
  justify-content: space-around;
}
</style>
