<template>
  <div class="container">
    <div class="column">
      <div class="title row" v-for="title in row" :key="title">{{ title }}</div>
    </div>
    <div class="column" v-for="(item, index) in datalist" :key="index">
      <div class="row" v-for="content in item" :key="content">
        {{ content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "t-table",
  props: {
    row: {
      type: Array,
      required: true,
    },
    datalist: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
.container {
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #ddd;
  height: min-content;
  overflow-x: scroll;
}
.container::-webkit-scrollbar {
  display: none;
}
.column {
  width: min-content;
  height: 2em;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}
.title {
  color: gray;
}
.row {
  width: 10em;
  white-space: nowrap;
  border-left: 1px solid #ddd;
  height: 2em;
  line-height: 2em;
  display: inline-block;
  text-align: center;
}
.column > .row:nth-child(1) {
  border-left: 1px solid transparent;
}
</style>
