import one from './phone/one_go.vue'
import two from './phone/two_go.vue'
import three from './phone/three_go.vue'
import four from './phone/four_go.vue'
import onew from './window/one_go.vue'
import twow from './window/two_go.vue'
import threew from './window/three_go.vue'
import fourw from './window/four_go.vue'
import {defineCustomElement} from 'vue'

export default function(){
    customElements.define('phone-investone',defineCustomElement(one))
    customElements.define('phone-investtwo',defineCustomElement(two))
    customElements.define('phone-investthree',defineCustomElement(three))
    customElements.define('phone-investfour',defineCustomElement(four))
    
    customElements.define('window-investone',defineCustomElement(onew))
    customElements.define('window-investtwo',defineCustomElement(twow))
    customElements.define('window-investthree',defineCustomElement(threew))
    customElements.define('window-investfour',defineCustomElement(fourw))

}