<template>
  <div class="container" :style="setstyle">
    <div class="main" :style="gClipPath()"></div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  name: "common-g",
  setup() {
    const radius = 100;
    const startX = radius;
    const startY = 0;
    const angle = ref(0);
    return {
      startX,
      startY,
      radius,
      angle,
    };
  },
  props: {
    angleo: {
      type: Number,
      required: true,
    },
    setstyle: {
      type: String,
    },
    interval: {
      type: Number,
      default: 5,
    },
  },
  mounted() {
    const maxAngle = Math.round(this.angleo);
    this.angle = 0;
    const timer = setInterval(() => {
      if (this.angle == maxAngle) {
        clearInterval(timer);
      } else {
        this.angle++;
      }
    }, this.interval);
  },
  methods: {
    angleTransform(angle) {
      return ((angle + 90) / 180) * Math.PI;
    },
    getPos(angle) {
      return {
        x: Math.cos(angle) * this.radius + this.radius,
        y: Math.sin(angle) * this.radius + this.radius,
      };
    },
    posTransform({ x, y }) {
      return {
        x: this.radius * 2 - x,
        y: this.radius * 2 - y,
      };
    },
    compose(params, ...funcs) {
      let ret = params;
      funcs.forEach((func) => {
        ret = func(ret);
      });
      return ret;
    },
    gPath({ x, y }) {
      if (this.angle <= 45) {
        return `${this.startX}px ${this.startY}px,${x}px 0,${x}px ${y}px,50% 50%`;
      } else if (this.angle <= 135) {
        return `${this.startX}px ${this.startY}px,100% 0,100% ${y}px,${x}px ${y}px,50% 50%`;
      } else if (this.angle <= 225) {
        return `${this.startX}px ${this.startY}px,100% 0,100% 100%,${x}px 100%,${x}px ${y}px,50% 50%`;
      } else if (this.angle <= 315) {
        return `${this.startX}px ${this.startY}px,100% 0,100% 100%,0 100%,0 ${y}px,${x}px ${y}px,50% 50%`;
      } else if (this.angle <= 360) {
        return `${this.startX}px ${this.startY}px,100% 0,100% 100%,0 100%,0 0,${x}px 0,${x}px ${y}px,50% 50%`;
      } else {
        return "";
      }
    },
    mapPos() {
      return this.compose(
        this.angle,
        this.angleTransform,
        this.getPos,
        this.posTransform
      );
    },
    gClipPath() {
      const endPos = this.mapPos();
      const clipPath = `clip-path:polygon(${this.gPath(endPos)})`;
      return clipPath;
    },
  },
};
</script>

<style scoped>
.container {
  --radius-n: 100px;
  width: calc(var(--radius-n) * 2);
  height: calc(var(--radius-n) * 2);
  background-color: #666;
  border-radius: 50%;
  position: relative;
}
.main {
  width: calc(var(--radius-n) * 2);
  height: calc(var(--radius-n) * 2);
  background-color: #93d500;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 60px;
}
.container::after {
  content: "";
  width: 80px;
  height: 80px;
  background: white;
  border-radius: 50%;
  position: absolute;
  left: 60px;
  top: 60px;
}
</style>
