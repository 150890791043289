import Code from './components/Code_go.vue'
import Button1 from './components/Button93d500_go.vue'
import Button2 from './components/Buttonrgba24224224277_go.vue'
import Box0 from './components/CommonLogoBox_go.vue'
import Box1 from './components/CommonTextBox_go.vue'
import preload from './components/CommonPreload_go.vue'
import watch from './components/CommonWatch_go.vue'
import workenv from './components/CommonWorkEnv_go.vue'
import arrow from './components/CommonDashArrow_go.vue'
import Input from './components/CommonInput_go.vue'
import InputC from './components/CommonInputColumn_go.vue'
import Toast from './components/CommonToast_go.vue'
import PieGraph from './components/CommonPieGraph_go.vue'
import Histogram from './components/CommonHistogram_go.vue'
import Table from './components/CommonTable_go.vue'
import TablePro from './components/CommonTablePro_go.vue'
import Card from './components/CommonCard_go.vue'
import List from './components/CommonList_go.vue'
import { defineCustomElement } from 'vue'
export default function () {
    customElements.define('common-code', defineCustomElement(Code))
    customElements.define('common-button93d500', defineCustomElement(Button1))
    customElements.define('common-button24277', defineCustomElement(Button2))
    customElements.define('common-logobox', defineCustomElement(Box0))
    customElements.define('common-textbox', defineCustomElement(Box1))
    customElements.define('common-preload', defineCustomElement(preload))
    customElements.define('common-watch', defineCustomElement(watch))
    customElements.define('common-workenv', defineCustomElement(workenv))
    customElements.define('common-dasharrow', defineCustomElement(arrow))
    customElements.define('common-input', defineCustomElement(Input))
    customElements.define('common-toast', defineCustomElement(Toast))
    customElements.define('common-inputcolumn', defineCustomElement(InputC))
    customElements.define('common-piegraph', defineCustomElement(PieGraph))
    customElements.define('common-histogram', defineCustomElement(Histogram))
    customElements.define('common-table', defineCustomElement(Table))
    customElements.define('common-tablepro', defineCustomElement(TablePro))
    customElements.define('common-card', defineCustomElement(Card))
    customElements.define('common-list', defineCustomElement(List))
}