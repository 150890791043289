<template>
  <div class="container">
    <div class="arrowTop"></div>
    <div class="arrowMiddle"></div>
    <div class="arrowBottom"></div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.container {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  display: inline-block;
}

.arrowTop,
.arrowBottom,
.arrowMiddle {
  position: absolute;
  box-sizing: border-box;
  width: 100px;
  margin: 49px 0;
  height: 2px;
  border-bottom: 2px dashed rgb(216, 224, 236);
}
.arrowTop,
.arrowBottom {
  width: 30px;
  margin-left: 70px;
  transform-origin: right;
}
.arrowTop {
  transform: rotate(30deg);
}
.arrowBottom {
  transform: rotate(-30deg);
}
</style>
