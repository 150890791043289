<template>
  <div class="container" :style="vh2px('height', 100)">
    <div class="top" :style="vh2px('padding-top', 15)">
      <h4 class="secTitle" :style="vh2px('margin-bottom', 2)">
        共创泛营地教育新生态
      </h4>
      <h1 class="title">欢迎加入营探平台</h1>
      <div class="code" :style="vh2px('padding-top', 8)">
        <common-code :setstyle="setCodeStyle"></common-code>
      </div>
    </div>
    <div class="button" :style="vh2px('padding-bottom', 15)">
      <common-button93d500 :setstyle="setButtonStyle" @click="goto"
        >立即入驻</common-button93d500
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "e-e",
  created() {
    // console.log(this);
  },
  setup() {
    return {
      setButtonStyle: "width:90vw",
      setCodeStyle: "margin-left:0",
    };
  },
  inject: ["vh2px", "reactive"],
  methods: {
    goto() {
      location.href = `${location.origin}/dynamic.html/infoCollect`;
    },
    composeStyle(...styles) {
      return styles.join(";");
    },
  },
};
</script>

<style>
.container {
  width: 100vw;
  /* height: 100vh; */
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  --padding-left: 5vw;
}
.top {
  /* padding-top: 15vh; */
}
.code {
  height: min-content;
  width: 90vw;
  padding: var(--padding-left);
  display: flex;
  justify-content: center;
  /* padding-top: 8vh; */
}
.button {
  /* padding-bottom: 15vh; */
  display: flex;
  flex-wrap: nowrap;
  width: 90vw;
  padding-left: var(--padding-left);
  justify-content: center;
}
.title {
  font-size: 2.5em;
  font-weight: bold;
  color: #4d4d4d;
  /* font-family: youshe; */
  white-space: nowrap;
  margin-top: 0;
  margin-bottom: 7vw;
  padding-left: var(--padding-left);
  padding-top: 0;
}
.secTitle {
  font-size: 1.5em;
  /* margin-bottom: 2vh; */
  color: #93d500;
  padding-left: var(--padding-left);
}
</style>
