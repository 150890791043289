<template>
  <go-container :isnoscroll="true" root="go-infocollectwindow" pagename="招商">
    <div class="container" :style="vh2px('padding-top', 10)">
      <div class="content">
        <div class="title">欢迎您入驻营探,我们的工作人员将尽快与您联系</div>
        <div class="form">
          <common-inputcolumn
            v-for="(
              { showerror, name, hint, regular, required, error, label }, i
            ) in formInfo"
            :key="i"
            :showerror.prop="{ value: showerror }"
            :name="name"
            :hint="hint"
            :regular.prop="{ value: regular }"
            :required.prop="{ value: required }"
            :error="error"
            :label="label"
            :index="i"
            :isinputpaler="true"
            @submit="submit"
          ></common-inputcolumn>
          <div class="button">
            <common-button93d500 :setstyle="setButtonStyle" @click="post">
              提交申请
            </common-button93d500>
          </div>
        </div>
      </div>
      <div class="bottom"></div>
    </div>
    <common-toast v-bind="toast" :show.prop="{ value: show }"></common-toast>
  </go-container>
</template>

<script>
import { ref } from "@vue/reactivity";
import formInfo, { verifyRequest } from "../asset-infoCollect/js/formInfo.js";
export default {
  name: "info-collect",
  setup() {
    const body = ref({});
    const verifyState = ref(new Array(formInfo.length).fill(false));
    const setButtonStyle = `transform:scale(0.8)`;
    const show = ref(false);
    const toast = ref({ type: "success", content: "提交成功" });
    const setout = "";
    return {
      formInfo,
      body,
      setButtonStyle,
      verifyState,
      toast,
      show,
      setout,
    };
  },
  methods: {
    vh2px(key, value) {
      return `${key}:${(window.innerHeight / 100) * value}px`;
    },
    $toast(data) {
      clearTimeout(this.setout);
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, 2000);
      this.toast = data;
    },
    submit(e) {
      console.log(e);
      this.body = { ...this.body, [e.detail[0].name]: e.detail[0].value };
      this.verifyState[e.detail[1]] = e.detail[2];
    },
    async post() {
      if (this.verifyState.every((r, i) => r || !formInfo[i].required)) {
        const ret = await verifyRequest(this.body);
        if (ret.Code == 200) {
          this.$toast({
            type: "success",
            content: "提交成功!我们将会尽快联系您",
          });
        } else {
          this.$toast({ type: "error", content: "提交失败!请重试" });
        }
      } else {
        this.$toast({ content: "请检查填写内容！", type: "error" });
      }
    },
  },
};
</script>

<style>
.container {
  padding: 0;
  /* padding-top: 15vh; */
  /* height: 85vh; */
  width: 100vw;
  margin: 0;
  --i-title-size: 18px;
  --i-sec-size: 16px;
  --i-content-size: 14px;
}
.title {
  font-size: var(--i-title-size);
  text-align: left;
  padding: 2rem 0 1rem;
  width: 100%;
}
.content {
  display: flex;
  /* height: calc(60vh - 2rem); */
  width: 80vw;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
</style>
