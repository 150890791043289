import axios from "axios";
export const auth = {
  username:
    "NginxAuthUsername16381efa069a90cbde99854c81183036a4bd2e4776eb770fa69740f1c5c06aa4",
  password:
    "NginxAuthPassworde0588e3b3c5b240fe3f5fb653d2990f34b52ee0b595acf6553a924b0716cb366",
};
export const headers = {
  "Content-Type": "application/json",
};
export default function request(config) {
  const instance = axios.create({
    baseURL: "",
    timeout: 60000,
  });
  instance.interceptors.request.use(
    (config) => config,
    (err) => {
      console.log(err);
    }
  );
  instance.interceptors.response.use(
    (res) => res.data,
    (err) => {
      console.log(err);
    }
  );
  return instance({ auth, headers, ...config });
}
