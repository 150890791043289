<template>
  <div class="container" :style="setstyle">
    <div
      class="logo"
      :style="gBackground()"
      @mouseenter="mouseEnter"
      @mouseleave="mouseLeave"
    ></div>
    <div class="textBox">
      <div class="title" :style="settitlestyle"><slot></slot></div>
      <div class="content">{{ content }}</div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  name: "common-logobox",
  setup() {
    const state = ref(0);
    return { state };
  },
  props: {
    img: {
      required: true,
      type: Array,
    },
    content: {
      required: true,
      type: String,
    },
    setstyle: {
      type: String,
    },
    settitlestyle: {
      type: String,
    },
  },
  methods: {
    mouseEnter() {
      this.state = 1;
    },
    mouseLeave() {
      this.state = 0;
    },
    gBackground() {
      return `background-image:url(${this.img[this.state]})`;
    },
  },
};
</script>

<style>
.container {
  width: 40vw;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.logo {
  width: 4.5em;
  height: 4.5em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  flex-shrink: 0;
}
.textBox {
  padding: 0 1em;
  /* font-weight: lighter; */
  color: #4d4d4d;
  line-height: 2;
}
.title {
  font-size: 1.2em;
  font-weight: 500;
}
</style>
