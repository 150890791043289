<template>
  <div class="container" :style="vh2px('height', 100)">
    <div class="title" :style="vh2px('margin-top', 10)">
      <span>营探</span> 科技驱动型平台
    </div>
    <div class="env" :style="vh2px('height', 35)">
      <common-workenv :setstyle="scale"></common-workenv>
    </div>
    <div class="textBox">
      <!-- <common-textbox
          :setstyle="setBoxStyle"
          v-for="(v, i) in list"
          :content="v.content"
          :key="i"
          >{{ v.title }}</common-textbox
        > -->
      <div class="secTitle">聚焦泛营地市场</div>
      <div class="content">
        <div>
          定制工具&nbsp;&nbsp; 𐌠 &nbsp;&nbsp;花样跨界&nbsp;&nbsp; 𐌠
          &nbsp;&nbsp;价值认同
        </div>
        <div>用科技、智慧、文化的力量、践行报国</div>
      </div>
      <div class="buttonGroup">
        <div class="buttonTitle">一分钟填写入驻申请</div>
        <div class="buttonBox">
          <common-button93d500
            toast="适用于企业、个体工商户、事业单位、社会团体等、须提供营业执照"
            @click="goto"
            setstyle="transform:scale(0.8)"
            :auto.prop="true"
            :off.prop="toggleToast[0]"
            >机构入驻</common-button93d500
          >
          <common-button93d500
            toast="适用于个人、未进行工商登记、利用个人技能从事小额相关业务的"
            right="true"
            @click="goto"
            setstyle="transform:scale(0.8)"
            :auto.prop="true"
            :off.prop="toggleToast[1]"
          >
            个人入驻</common-button93d500
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { three } from "../../js/document";
export default {
  name: "e-e",
  inject: ["vh2px"],
  setup() {
    // const toggleToast = ref([true, false]);
    return {
      scale: `transform:scale(${(window.innerHeight * 1.5) / 6670})`,
      setBoxStyle: "width:130vw;transform:scale(0.7);transform-origin:left",
      list: three.data,
      toggleToast: [true, true],
    };
  },
  methods: {
    goto() {
      location.href = `${location.origin}/dynamic.html/infoCollect`;
    },
  },
  created() {
    // clearInterval(this.setT);
    // this.setT = setInterval(() => {
    //   this.toggleToast = [...this.toggleToast.reverse()];
    // }, 5000);
  },
};
</script>

<style>
.container {
  width: 100vw;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  --padding-left: 5vw;
  justify-content: space-between;
  padding-left: var(--padding-left);
  --margin-bottom: min(2vh, 2rem);
}
.title {
  font-size: 2.5em;
  font-weight: bold;
  /* font-family: youshe; */
  white-space: nowrap;
  /* margin-top: 10vh; */
  color: #4d4d4d;
  margin-bottom: 0.2rem;
}
.title span {
  color: #93d500;
}
.top {
  padding-left: var(--padding-left);
}
.env {
  width: 90vw;
  /* height: 5vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.textBox {
  height: min-content;
  flex-grow: 1;
  margin-bottom: 5em;
}
common-workenv {
  flex-shrink: 1;
  /* width: 1356px; */
  /* height: 1415px; */
  transform-origin: center;
}
.secTitle,
.buttonTitle {
  font-size: 1.2rem;
  color: #4d4d4d;
  margin-bottom: var(--margin-bottom);
  padding-left: 2.5rem;
  font-weight: bold;
}
.content {
  line-height: 2;
  font-size: 0.8rem;
  padding-left: 2.5rem;
  color: #666;
  margin-bottom: var(--margin-bottom);
}
.buttonTitle {
  margin-top: calc(2 * var(--margin-bottom));
}
.buttonBox {
  width: 90vw;
  display: flex;
  justify-content: center;
}
.buttonBox > *:nth-child(1) {
  margin-right: 1em;
}
</style>
