const document = [
    {
        title: "欢迎加入营探平台",
        secTitle: "开启泛营地活动预订新篇章"
    },
    {
        title: "我们的优势",
        data: [
            { title: "快速认证  提升商家权重", detail: "营探核心商家助力池等你Jump In" },
            { title: "新商户 丰富见面礼", detail: "经营攻略免费学 𐌠 安全守护 𐌠 资讯分享" },
            { title: "流量扶持 多维立体展示", detail: "主页流量扶持 𐌠 私域流量扶持 𐌠 品牌联合推广" },
            { title: "数字化工作台 运营新革命", detail: "一站式促销管理 𐌠 学员信息管理  𐌠 资金管理" }
        ]
    },
    {
        data: [
            {
                title: "法人",
                content:
                    "有相关业务、相关资质的，在中国境内注册的，以盈利为目的、从事生产经营和服务活动的独立核算经济组织，包括有限责任公司、股份有限公司等。",
            },
            {
                title: "非法人组织",
                content:
                    "有相关业务、相关资质的，在中国境内的，依法以自己的名义从事民事活动的组织，如个人独资企业、合伙企业、不具有法人资格的专业服务机构等。",
            },
            {
                title: "个体工商户",
                content: "自然人从事相关业务经营，依法登记为个体工商户的。",
            },
            {
                title: "自然人",
                content:
                    "自然人个人利用自己的技能，从事零星小额相关业务活动但未进行工商登记的。",
            },
        ],
    },
    {
        data: [
            { index: 1, title: "申请入驻", steps: [{ content: "留下联系方式" }, "(1) 营探招商-立即入驻", "(2) APP/小程序-我的-业务合作", "*正确填写注册手机,注册邮箱务"] },
            { arrow: true },
            {
                index: 2, title: "提交资料", steps: [{ content: "实名认证" }, "APP/小程序-我的-实名认证", "*注册手机号,姓名,身份证号为同一人",
                { content: "提交入驻资料" }, "入驻资料清单查收注册邮箱"]
            },
            { arrow: true },
            { index: 3, title: "审核签约", steps: [{ content: "激活工作台" }, "(1) 查收注册邮箱激活邮件", "(2) 设置用户名密码", { content: "提交入驻申请" }, "登录工作台填写基础信息", { content: "电子签约" }, "(1) 查收注册手机短信", "(2) 根据提示完成协议签约"] },
            { arrow: true },
            { index: 4, title: "账户激活", steps: ["设置主理人页面", "设置商品信息", "申请核保-上架", "履行服务商保证金"].map(content => ({ content })) }
        ],
    }
];
export const one = document[0]
export const two = document[1]
export const three = document[2]
export const fourW = document[3]
export const fourP = {
    data: [
        { index: 1, title: "申请入驻", steps: [{ content: "留下联系方式" }, "(1) 营探招商-立即入驻", "(2) APP/小程序-我的-业务合作", "*正确填写注册手机,注册邮箱务"] },
        { arrow: true },
        {
            index: 2, title: "提交资料", steps: [{ content: "实名认证" }, "APP/小程序-我的-实名认证", "*注册手机号,姓名,身份证号为同一人",
            { content: "提交入驻资料" }, "入驻资料清单查收注册邮箱"]
        },
        { arrow: true },
        { index: 3, title: "审核签约", steps: [{ content: "激活工作台" }, "(1) 查收注册邮箱激活邮件 (2) 设置用户名密码", { content: "提交入驻申请&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;登录工作台填写基础信息" }, { content: "电子签约" }, "(1) 查收注册手机短信 (2) 根据提示完成协议签约"] },
        { arrow: true },
        { index: 4, title: "激活账户", steps: ["设置主理人页面", "设置商品信息", "申请核保-上架", "履行服务商保证金"].map(content => ({ content })) }
    ]
}
