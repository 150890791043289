<template>
  <go-container root="go-investmentwindow" pagename="招商" arrowBg="#93d500">
    <section>
      <window-investone></window-investone>
    </section>
    <section>
      <window-investtwo></window-investtwo>
    </section>
    <section>
      <window-investthree></window-investthree>
    </section>
    <section>
      <window-investfour></window-investfour>
    </section>
  </go-container>
</template>

<script>
export default {
  name: "e-e",
  created() {},
};
</script>

<style>
section {
  height: 100vh;
}
</style>
