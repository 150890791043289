<template>
  <div class="container" :style="setstyle">
    <div class="watch item">
      <common-watch scale="2.86"></common-watch>
    </div>

    <div
      v-for="[className, ...params] in relativePos"
      :style="gStyle(...params)"
      :key="className"
      :class="'item ' + className"
    ></div>
    <!-- <div class="circle"></div> -->
  </div>
</template>

<script>
import border from "../../asset-investment/img/common/page3/border.png";
import computer from "../../asset-investment/img/common/page3/computer.png";
import correct from "../../asset-investment/img/common/page3/correct.png";
import cup from "../../asset-investment/img/common/page3/cup.png";
import dash from "../../asset-investment/img/common/page3/dash.png";
import desktop from "../../asset-investment/img/common/page3/desktop.png";
import flog from "../../asset-investment/img/common/page3/flog.png";
import holder from "../../asset-investment/img/common/page3/holder.png";
import lines from "../../asset-investment/img/common/page3/lines.png";
import moutain from "../../asset-investment/img/common/page3/moutain.png";
import note from "../../asset-investment/img/common/page3/note.png";
import people from "../../asset-investment/img/common/page3/people.png";
import peopleborder from "../../asset-investment/img/common/page3/peopleborder.png";
import photoborder from "../../asset-investment/img/common/page3/photoborder.png";
import screen from "../../asset-investment/img/common/page3/screen.png";
export default {
  name: "work-env",
  props: ["setstyle"],
  setup() {
    return {
      relativePos: [
        ["border", 155, 225, 459, 654, border],
        ["computer", 795, 540, 349, 545, computer],
        ["correct", 90, 90, 1147, 373, correct],
        ["cup", 776, 193, 103, 1005, cup],
        ["dash", 315, 298, 911, 381, dash],
        ["desktop", 1279, 51, 77, 1196, desktop],
        ["circle", 157.5, 157.5, 459, 654, ""],
        ["flog", 305, 577, 0, 437, flog],
        ["holder", 131, 80, 677, 1081, holder],
        ["lines", 230, 313, 989, 1002, lines],
        ["moutain", 604, 252, 700, 723, moutain],
        ["note", 407, 490, 939, 925, note],
        ["people", 61, 77, 506, 694, people],
        ["peopleBorder", 224, 300, 425, 615, peopleborder],
        ["photoBorder", 367, 274, 699, 615, photoborder],
        ["screen", 781, 526, 356, 552, screen],
      ],
    };
  },
  methods: {
    gStyle(width, height, left, top, src) {
      return `width:${width}px;height:${height}px;left:${left}px;top:${top}px;background-image:url(${src})`;
    },
  },
};
</script>

<style>
.container {
  position: relative;
  width: 1356px;
  height: 1415px;
}
.item {
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.item.watch {
  width: 286px;
  height: 286px;
  left: 267px;
  top: 0;
}
.peopleBorder,
.note,
.photoBorder,
.cup {
  z-index: 2;
}
.lines,
.border,
.moutain {
  z-index: 3;
}
.people {
  z-index: 4;
}
.circle {
  border-radius: 50%;
  border: 2px solid #93d500;
  box-sizing: border-box;
  opacity: 0;
  transform-origin: left;
  transform: scale(0);
  transition: transform 0.5s cubic-bezier(0.08, 2.69, 0.03, 2),
    opacity 0.5s ease, left 0.5s ease, top 0.5s ease;
}
/* .desktop{
  z-index: 20;
}
.desktop:hover + .circle {
  opacity: 1;
  transform: scale(2);
  z-index: 10;
  left: 1500px!important;
  top: 381px!important;
}
.desktop:hover{
  transform: scale(1.5);
} */
</style>
