<template>
  <div class="container">
    <div class="left">
      <div class="leftBox">
        <h4 class="secTitle">共创泛营地教育新生态</h4>
        <h1 class="title">欢迎加入营探平台</h1>
        <div class="buttonGroup">
          <!-- <div class="shouldKnow">
            登录即视为您已同意d 
            <a :href="gHerf('/agreements.html/service')">营探用户协议</a>、<a :href="gHerf('/agreements.html/privacy')">营探隐私政策</a>、<a>风险知情书</a>
          </div> -->
          <common-button93d500
            :setstyle="composeStyle(gScale, gSpace, gOrigin)"
            @click="goto"
            >立即入驻</common-button93d500
          >
          <common-button24277
            :setstyle="composeStyle(gScale, gOrigin)"
            @click="login"
            >登录工作台</common-button24277
          >
        </div>
      </div>
    </div>
    <div class="right">
      <common-code></common-code>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
export default {
  name: "e-e",
  inject: ["reactive"],
  setup() {
    const gSpace = computed(() => `margin-right:1em`);
    const gScale = computed(() => `transform:scale(0.8)`);
    const gOrigin = computed(() => `transform-origin:left`);
    const origin = window.location.origin;
    return {
      gSpace,
      gScale,
      gOrigin,
      origin,
    };
  },
  methods: {
    gHerf(path) {
      return this.origin + path;
    },
    goto() {
      location.href = `${location.origin}/dynamic.html/infoCollect`;
    },
    login() {
      location.href = `${location.origin}/internal.html/tools/login`;
    },
    composeStyle(...styles) {
      return styles.join(";");
    },
  },
};
</script>

<style>
.container {
  width: 100vw;
  height: 100vh;
  min-width: 1000px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.buttonGroup {
  padding-bottom: 3em;
  min-width: 400px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.shouldKnow {
  transform: translateY(100%);
  width: 0;
  white-space: nowrap;
  font-size: 0.7rem;
  color: #4d4d4d;
}
.shouldKnow > a {
  text-decoration: none;
  color: #93d500;
}
.buttonGroup .space {
  /* margin-right: 2em; */
}
.left,
.right {
  flex-grow: 1;
  width: min-content;
  min-width: 500px;
  display: flex;
}
.right {
  padding-top: 5em;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.left {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.leftBox {
  padding-left: 5em;
}
.title {
  font-size: 4em;
  font-weight: bold;
  color: #4d4d4d;
  /* font-family: youshe; */
  white-space: nowrap;
  margin-top: 0;
  margin-bottom: 7vw;
  padding-top: 0;
}
.secTitle {
  font-size: 1.5em;
  margin-bottom: 0;
  color: #93d500;
}
</style>
