<template>
  <div class="container">
    <div class="scaleBox">
      <div class="scale holder"></div>
      <div class="scale" v-for="v in column" :key="v">{{ v }}</div>
    </div>
    <div class="dataBox">
      <div
        class="data"
        v-for="{ time, value, label } in dyData"
        :key="time"
        :data-time="time"
        :data-value="label"
        :style="gHeight(value)"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  name: "w-w",
  props: {
    max: {
      type: Number,
      default: 200,
    },
    scaleNum: {
      type: Number,
      default: 5,
    },
    data: {
      type: Array,
      default: () => [
        {
          time: "2/1",
          value: 100,
        },
        {
          time: "2/1",
          value: 10,
        },
        {
          time: "2/1",
          value: 3,
        },
        {
          time: "2/1",
          value: 60,
        },
        {
          time: "2/1",
          value: 100,
        },
        {
          time: "2/1",
          value: 100,
        },
        {
          time: "2/1",
          value: 10,
        },
        {
          time: "2/1",
          value: 3,
        },
        {
          time: "2/1",
          value: 60,
        },
        {
          time: "2/1",
          value: 100,
        },
        {
          time: "2/1",
          value: 3,
        },
        {
          time: "2/1",
          value: 60,
        },
        {
          time: "2/1",
          value: 100,
        },
        {
          time: "2/1",
          value: 3,
        },
        {
          time: "2/1",
          value: 60,
        },
        {
          time: "2/1",
          value: 100,
        },
        {
          time: "2/1",
          value: 3,
        },
        {
          time: "2/1",
          value: 60,
        },
        {
          time: "2/1",
          value: 100,
        },
        {
          time: "2/1",
          value: 3,
        },
        {
          time: "2/1",
          value: 60,
        },
        {
          time: "2/1",
          value: 100,
        },
        {
          time: "2/1",
          value: 3,
        },
        {
          time: "2/1",
          value: 60,
        },
        {
          time: "2/1",
          value: 10,
        },
      ],
    },
  },
  setup() {
    const dyData = ref([]);
    const intervalNum = 50;
    return {
      dyData,
      intervalNum,
    };
  },
  methods: {
    gHeight(height) {
      return `height:${height}px`;
    },
  },
  mounted() {
    const origin = this.data;
    const dyData = new Array(origin.length).fill(0).map((r, i) => ({
      time: origin[i].time,
      interval: origin[i].value / this.intervalNum,
      value: 0,
      label: " ",
    }));
    this.dyData = dyData;
    let i = 0;
    const timer = setInterval(() => {
      if (i == this.intervalNum) {
        clearInterval(timer);
        this.dyData.forEach((r, i) => {
          r.label = origin[i].value;
        });
        return;
      }
      i++;
      this.dyData.forEach((r) => {
        r.value += r.interval;
      });
    }, 10);
  },
  computed: {
    column() {
      const ret = [""];
      const interval = this.max / this.scaleNum;
      let i = interval;
      while (i <= this.max) {
        ret.push(i);
        i += interval;
      }
      return ret;
    },
  },
};
</script>

<style>
.container {
  width: 100%;
  height: 250px;
  box-sizing: border-box;
  overflow-x: scroll;
  overflow-y: visible;
}
.scaleBox,
.dataBox {
  display: inline-flex;
  height: 100%;
}
.container::-webkit-scrollbar {
  display: none;
}
.scaleBox {
  width: 2em;
  height: 200px;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  z-index: 6;
  left: 0;
  background: white;
  opacity: 1;
  border-right: 2px solid #ddd;
}
.data::after,
.data::before {
  width: 1.5em;
  text-align: center;
  content: "";
  position: absolute;
  z-index: 3;
}
.data::after {
  content: attr(data-time);
  bottom: -2em;
}
.data::before {
  content: attr(data-value);
  top: -2em;
}
.holder {
  height: 45px;
  width: 2em;
  position: absolute;
  background: white;
  opacity: 1;
  top: 200px;
  border: 2px solid white;
}
.data {
  width: 1.5em;
  display: inline-block;
  position: relative;
  overflow: visible;
  background-color: #93d500;
  padding: 0 1em;
  flex-shrink: 0;
  border-bottom: 2px solid #93d500;
  background-clip: content-box;
}
.dataBox {
  width: calc(600px - 2em);
  height: 200px;
  /* overflow-x: scroll; */
  /* overflow-y: hidden; */
  align-items: flex-end;
}
.dataBox::-webkit-scrollbar {
  display: none;
}
</style>
