<template>
  <div
    class="header"
    :style="gBackgroundImage(isPhone ? headerSrcPhone : headerSrc)"
  >
    <div class="yingtan">
      <div class="wrapper">
        <img class="yingtanimg" :src="yingtanSrc" alt="" />
        <span>{{ pagename }}</span>
      </div>
      <a class="backTo" href="/">返回官网</a>
    </div>
    <div class="header-upper">
      <div class="routerTest"></div>
    </div>
    <div class="header-lower"></div>
  </div>
  <button
    v-if="!isnoscroll"
    class="bt-down"
    :class="{ 'bt-up': nextSectionIndex === 0 }"
    @click="scrollToNextSection"
  >
    <div class="arrow" :style="gBackgroundImage(arrowSrc)"></div>
  </button>
  <slot></slot>
</template>

<script>
// 因为该容器来自Camptogo.vue,文件相对路径要保持一致,这个容器提供了1 页面顶部的绿色fix 2页面的按钮滚动（每个板块要用section包） 以及一些小的地方
import { ideaScreen } from "./assets/js/ideaScreen.js";
import { isPhone } from "./index.config";
import headerSrc from "./assets/img/headerwithfrog.png";
import arrowSrc from "./assets/down-arrow.svg";
import headerSrcPhone from "./assets/img/header-mobile.png";
import yingtanSrc from "./assets/white-yintan.png";
const vh = window.innerHeight / 100;
function vh2px(target, value) {
  return `${target}:${value * vh}px`;
}
export default {
  name: "e-container",
  components: {},
  provide() {
    return {
      isPhone,
      vh2px,
      reactive: this.reactive,
    };
  },
  props: {
    root: {
      required: true,
    },
    isnoscroll: {
      type: Boolean,
    },
    arrowBg: {
      type: String,
    },
    pagename: {
      required: true,
      type: String,
    },
  },
  setup() {
    return {
      headerSrc,
      arrowSrc,
      headerSrcPhone,
      yingtanSrc,
    };
  },
  created() {},
  data() {
    return {
      nextSectionIndex: 1,
      isPhone,
      screenH: window.innerHeight,
      screenW: window.innerWidth,
      scrollTop: 0,
    };
  },
  computed: {
    rootDom() {
      return document.querySelector(this.root);
    },
    sections() {
      return this.rootDom.shadowRoot
        .querySelector("go-container")
        .querySelectorAll("section");
    },
    reactive() {
      const device = this.isPhone ? "phone" : "window";
      const wpx = this.screenW / ideaScreen[device].width;
      const hpx = this.screenH / ideaScreen[device].height;
      return {
        wpx,
        hpx,
        ipx: Math.min(wpx, hpx),
      };
    },
  },
  methods: {
    gArrowBg() {
      if (!this.arrowBg) {
        return "";
      } else {
        return `background:${this.arrowBg}`;
      }
    },
    gBackgroundImage(src) {
      return `background-image:url(${src})`;
    },
    getLen() {
      return this.sections.length;
    },
    scrollToNextSection: function () {
      const { sections } = this;
      sections[this.nextSectionIndex].scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      this.nextSectionIndex = (this.nextSectionIndex + 1) % sections.length;
    },
    scrollListener(e) {
      const scrollTop =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.scrollY;
      this.scrollTop = scrollTop;
      const maxScrollHeight = document.body.scrollHeight;
      const len = this.getLen();
      //使得当网页中的版面数变化时代码依旧适用
      let points = new Array(len).fill(0).map((r, i) => ({
        point: (1 / len) * i - 0.01,
        nextSectionIndex: (i + 1) % len,
      }));
      for (let i = len - 1; i >= 0; i--) {
        const { point, nextSectionIndex } = points[i];
        if (scrollTop >= point * maxScrollHeight) {
          this.nextSectionIndex = nextSectionIndex;
          break;
        }
      }
    },
  },
  mounted() {
    !this.isnoscroll && window.addEventListener("scroll", this.scrollListener);
    window.addEventListener("resize", () => {
      if (!this.isPhone) {
        // 解决苹果浏览器下滑导航栏消失引起的页面resize
        this.screenH = window.innerHeight;
        this.screenW = window.innerWidth;
      }
      if (
        /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
        // ||this.screenH > this.screenW
      ) {
        this.isPhone = true;
      } else {
        this.isPhone = false;
      }
    });
  },
};
</script>
<style>
.backTo {
  text-decoration: none;
  cursor: pointer;
  color: white;
  font-size: max(1.5vw, 1em);
  font-weight: 200;
}
.backTo:hover {
  font-weight: 400;
}
.wrapper {
  display: flex;
  align-items: center;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  width: 100vw;
  height: 8.177vw;
  /* background-image: url(); */
  background-size: 100% 100%;
  transition: background 150ms, box-shadow 150ms;
  overflow: hidden;
}
.yingtan {
  position: absolute;
  top: 40%;
  left: 5%;
  transform: translateY(-50%);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 90%;
  align-items: center;
}
.yingtan span {
  padding-left: 1vw;
  margin-left: 1vw;
  border-left: min(0.2vw, 2px) solid white;
  color: white;
  font-weight: 200;
  font-size: max(1.5vw, 1em);
  line-height: 0.9;
}
.yingtanimg {
  width: 15vw;
}

.header-upper,
.header-lower {
  display: none;
}
.header-upper {
  height: 80%;
}
.routerTest {
  position: absolute;
  left: 200px;
}
.bt-down {
  margin: 25px 0;
  border: solid white;
  border-radius: 50%;
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 45px;
  height: 45px;
  /* background-color: transparent; */
  background: #93d500;
  z-index: 10001;
  /* 确保最高层级 */
  cursor: pointer;
}

.bt-up {
  transform: translate(-50%) rotate(-180deg);
  /* 旋转父盒子解决返回顶部箭头不居中问题 */
}

.arrow {
  position: absolute;
  /* background-image: url(); */
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  content: "";
  display: block;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
}

@media only screen and (max-width: 483px) {
  .header {
    height: 15vw;
    /* background-image: url(); */
  }

  .yingtanimg {
    width: min(32vw, 8em);
  }
  .yingtan {
    margin-top: 5px;
  }
}
</style>
