<template>
  <div class="container">
    <div class="columnBox" v-for="(item, i) in column" :key="i">
      <div
        class="column"
        v-for="(text, j) in item"
        :key="j"
        :style="fontstyle[i][j]"
      >
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    column: {
      type: Array,
      required: true,
    },
    fontstyle: {
      type: Array,
      required: true,
    },
  },
  name: "list-l",
};
</script>

<style>
.container {
  white-space: nowrap;
  width: 100%;
  min-width: min-content;
  overflow-x: scroll;
  display: flex;
  justify-content: space-around;
}
.container::-webkit-scrollbar {
  display: none;
}
.columnBox {
  display: inline-flex;
  flex-direction: column;
  width: min-content;
  align-items: stretch;
  flex-grow: 1;
}
.column {
  white-space: nowrap;
  padding: 0 1em;
  height: 3em;
  line-height: 3em;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.column:nth-last-child(1) {
  border-bottom: none;
}
</style>
