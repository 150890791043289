<template>
  <div class="container">
    <div class="left">
      <common-workenv></common-workenv>
    </div>
    <div class="right">
      <div>
        <div class="title"><span>营探</span> 科技驱动型平台</div>
        <div class="secTitle">聚焦泛营地市场</div>
      </div>
      <div class="content">
        <div>
          定制工具&nbsp;&nbsp; 𐌠 &nbsp;&nbsp;花样跨界&nbsp;&nbsp; 𐌠
          &nbsp;&nbsp;价值认同
        </div>
        <div>
          用科技&nbsp;&nbsp;,&nbsp;&nbsp;智慧&nbsp;&nbsp;,&nbsp;&nbsp;文化的力量&nbsp;&nbsp;,&nbsp;&nbsp;践行报国
        </div>
      </div>
      <div class="buttonGroup">
        <div class="buttonTitle">一分钟填写入驻申请</div>
        <div class="buttonBox">
          <common-button93d500
            toast="适用于企业、个体工商户、事业单位、社会团体等，须提供营业执照"
            @click="goto"
            >机构入驻</common-button93d500
          >
          <common-button93d500
            toast="适用于个人，未进行工商登记，利用个人技能从事小额相关业务的"
            right="true"
            @click="goto"
          >
            个人入驻</common-button93d500
          >
        </div>
      </div>
      <!-- <common-textbox v-for="(v, i) in list" :content="v.content" :key="i">{{
        v.title
      }}</common-textbox> -->
    </div>
  </div>
</template>

<script>
import { three } from "../../js/document";
export default {
  name: "e-e",
  setup() {
    return {
      list: three.data,
    };
  },
  methods: {
    goto() {
      location.href = `${location.origin}/dynamic.html/infoCollect`;
    },
  },
};
</script>

<style>
.title {
  font-size: 3.5em;
  font-weight: bold;
  /* font-family: youshe; */
  white-space: nowrap;
  margin-top: 18vh;
  color: #4d4d4d;
  margin-bottom: 1rem;
}
.title span {
  color: #93d500;
}
.content {
  line-height: 2;
  font-size: 1.15rem;
  color: #4d4d4d;
}
.buttonBox {
  display: flex;
  justify-content: flex-start;
}
.buttonBox > *:nth-child(1) {
  margin-right: 6rem;
}
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  min-width: 1000px;
}
.secTitle,
.buttonTitle {
  font-size: 1.7rem;
  color: #4d4d4d;
  font-weight: bold;
}
.buttonTitle {
  margin-bottom: 1em;
}
.left {
  width: 50vw;
  min-width: 500px;
  display: flex;
  justify-content: center;
  padding-top: 15vh;
  align-items: center;
}
.right {
  width: 45vw;
  min-width: 500px;
  padding-left: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 3em;
  height: 100vh;
}
common-textbox {
  margin-bottom: 1em;
}
common-workenv {
  transform: scale(0.3);
  flex-shrink: 1;
  width: 1356px;
  height: 1415px;
  transform-origin: center;
}
common-button93d500 {
  font-size: 0.1em;
}
</style>
