<template>
  <div class="container">
    <div class="label">
      <span :class="{ required: required.value, hide: !required.value }">*</span
      >{{ label }}
    </div>
    <input
      type="text"
      class="input"
      v-model="value"
      @input="verify"
      :placeholder="hint"
      :class="{ inputPaler: isinputpaler }"
    />
    <div class="error" :style="gColor()" v-if="!expected || showerror.value">
      {{ error }}
    </div>
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
export default {
  props: {
    label: {
      required: true,
      type: String,
    },
    hint: {
      required: true,
      type: String,
    },
    showerror: {
      required: true,
      type: Object,
    },
    regular: {
      required: true,
      type: Object,
    },
    name: {
      required: true,
      type: String,
    },
    error: {
      required: true,
      type: String,
    },
    required: {
      required: true,
      type: Object,
    },
    index: {
      required: false,
      type: Number,
    },
    errorcolor: {
      type: String,
    },
    isinputpaler: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    const expected = ref(true);
    const value = ref("");
    return {
      expected,
      value,
    };
  },
  methods: {
    gColor() {
      if (this.errorcolor) {
        return `color:${this.errorcolor}`;
      } else {
        return ``;
      }
    },
    verify() {
      // console.log(this.isinputpaler)
      const regular = this.regular.value;
      if (!regular(this.value)) {
        this.expected = false;
      } else {
        this.expected = true;
      }
      this.$emit(
        "submit",
        { name: this.name, value: this.value },
        this.index,
        this.expected
      );
    },
  },
};
</script>

<style>
.required,
.error {
  color: red;
}
.hide {
  color: transparent;
}
.container {
  --i-content-size: 14px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 35em;
  margin: 1em;
  margin-bottom: 2em;
}
.error {
  font-size: 0.8em;
  position: absolute;
  /* font-weight: bold; */
  transform: translate(208px, 30px);
}
.input {
  outline: none;
  font-size: var(--i-content-size) !important;
  height: calc(var(--i-content-size) * 2.2);
  width: 20em;
  border-radius: 8px;
  border: 1px solid #c8cccf;
  outline: 0;
  padding-left: 20px;
}
.inputPaler::placeholder {
  opacity: 0.5;
  font-size: 14px;
}
</style>
