<template>
  <div class="box">
    <img :src="v" alt="" v-for="(v, i) in load" :key="i" />
  </div>
</template>

<script>
export default {
  props: {
    load: { required: true, type: Array },
    fatherDom: { required: true, type: Object },
  },

  name: "pre-load",
  mounted() {
    setTimeout(() => {
      const dom = this.fatherDom.querySelector("common-preload");
      this.fatherDom?.removeChild(dom);
    }, 0);
  },
};
</script>

<style>
.box {
  width: 0;
  height: 0;
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: -10000;
  overflow: hidden;
}
.img {
  width: 0px;
  height: 0px;
}
</style>
